'use client'
import { Banner as BannerType } from '@/app/util/banner'
import { getArtists } from '@/app/util/channel-api'
import { publicRuntimeConfig } from '@/app/util/env'
import Banner from '@/components/banner'
import Grid from '@/components/grid/grid'
import VideoGrid from '@/components/grid/video-grid'
import _TV from '@/components/homepage/artist-tv'
import { SectionHeader } from '@/components/sections'
import UAParserContext from '@/context/ua-parser'
import { GiphyFetch, MediaType } from '@giphy/js-fetch-api'
import { IChannel, IGif } from '@giphy/js-types'
import { setGADataLayer } from 'analytics'
import { isEmpty } from 'lodash'
import { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import { desktop } from 'ui/src/css'
import TrendingIcon from '../icons/trending'
import Toggle from '@/components/toggle'

const GridHeader = styled.div`
    ${desktop(css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    `)}
`

const TV = styled(_TV)`
    display: none;
    ${desktop(css`
        display: block;
        position: absolute;
        top: 0;
        right: 1px;
    `)}
`

const PageContent = styled.div`
    margin-top: 6px;
    position: relative;
    ${desktop(css`
        margin-top: 0;
    `)}
`

type Props = {
    banner?: BannerType
    trendingGifs?: IGif[]
    artists?: IChannel[]
    children?: React.ReactNode
}

const fetchConfig = {
    clientMobile: 12,
    clientDesktop: 12,
} as const

const clientGiphyFetch = new GiphyFetch(publicRuntimeConfig.mobileApiKey)

const fetchArtists = async (offset: number) => getArtists({ offset, limit: 3 })
const getTrackingUrl = (page: number) => `/homepage/page/${page}`

// This page can be seen in production at /test
const HomeContents = ({ banner, trendingGifs = [], children }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    const contentTypes = ['gifs', 'stickers', 'clips'] as const
    const [type, setType] = useState<(typeof contentTypes)[number]>('gifs')

    const trending = (type?: MediaType) => async (offset: number) =>
        clientGiphyFetch.trending({
            offset,
            type,
            limit: deviceType === 'desktop' ? fetchConfig.clientDesktop : fetchConfig.clientMobile,
        })

    const gridFetch = type === 'gifs' ? trending() : type === 'stickers' ? trending('stickers') : trending('videos')
    return (
        <>
            <div className="mb-2.5 mt-1 flex flex-col gap-1.5 md:my-2.5 md:flex-col-reverse md:gap-2.5">
                <GridHeader>
                    <SectionHeader
                        Icon={<TrendingIcon className="size-5" />}
                        label="Trending Now"
                        tag="h1"
                        className="hidden md:inline-block"
                    />
                    <Toggle
                        className="bg-gradient-grey md:w-[400px]"
                        defaultIndex={0}
                        options={['GIFs', 'Stickers', 'Clips']}
                        onClick={(index: number) => {
                            setType(contentTypes[index] as (typeof contentTypes)[number])
                            setGADataLayer({
                                event: 'page_view',
                                options: {
                                    pageType: 'home',
                                    pageCategory: `homepage_${contentTypes[index]}`,
                                },
                            })
                        }}
                    />
                </GridHeader>
                {!isEmpty(banner) && <Banner banner={banner} />}
                {children}
            </div>
            <PageContent>
                {type === 'clips' ? (
                    <VideoGrid fetchGifs={gridFetch} gaTrackingUrl={getTrackingUrl} />
                ) : (
                    <Grid
                        key={type}
                        initialGifs={type === 'gifs' ? trendingGifs : undefined}
                        fetchGifs={gridFetch}
                        columnOffsets={type === 'gifs' ? [0, 0, 350, 350] : undefined}
                        gaTrackingUrl={getTrackingUrl}
                    />
                )}
                {type === 'gifs' && <TV width={512} height={342} fetchGifs={fetchArtists} />}
            </PageContent>
        </>
    )
}

export default HomeContents
