import { giphyBlack, giphyWhite } from '@giphy/colors'
import styled, { css } from 'styled-components'
import CornerCutout from 'ui/src/components/corner-cutout'
import _Attribution from './attribution'
import { IUser } from '@giphy/js-types'
import { Interface } from '@/styles/fonts'
import Username_ from './username'

const Wrapper = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 12px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
    opacity: 0;
    box-sizing: border-box;
    transition: opacity 0.2s ease-out;
`

const CutoutContainer = styled.div`
    position: absolute;
    top: -50px;
    right: -50px;
    width: 48px;
    height: 48px;
    transition: all 200ms ease-in-out;
`

const MovingCutout = styled(CornerCutout)`
    background-color: ${giphyBlack};
    transition: all 200ms ease-out;
    position: relative;
    width: 100%;
    height: 100%;
`

const Container = styled.div<{ isHovered: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    box-sizing: border-box;
    transition: opacity 0.2s ease-out;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 70px;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
        pointer-events: none;
    }

    ${(props) =>
        props.isHovered &&
        css`
            ${Wrapper} {
                opacity: 1;
            }

            ${CutoutContainer} {
                top: 0;
                right: 0;
            }
        `}
`

export const Username = styled(Username_)``

const Attribution = styled(_Attribution)`
    padding: 10px;
    ${Username} {
        color: rgba(255, 255, 255, 0.6);
    }
`

const Shadow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    transform: translate3d(0px, 0px, 0px);
`

const Bio = styled.p<{ isHovered: boolean }>`
    color: ${giphyWhite};
    font-family: ${Interface.style.fontFamily};
    font-weight: bold;
    padding-bottom: 10px;
    padding-left: 10px;

    white-space: pre-wrap;
    font-size: 14px;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    height: ${({ isHovered }) => (isHovered ? '100%' : '0px')};
    opacity: ${({ isHovered }) => (isHovered ? '1' : '0')};
    padding-bottom: ${({ isHovered }) => (isHovered ? '10px' : '0')};

    transition: all 200ms ease-in-out;
`

type Props = { user: IUser & { is_freelance?: boolean }; isHovered?: boolean; canViewForHire?: boolean }

const CutoutAttribution = ({ user, canViewForHire = false, isHovered = false }: Props) => (
    <Container isHovered={isHovered}>
        <Wrapper>
            <CutoutContainer>
                <MovingCutout isVisible />
            </CutoutContainer>
        </Wrapper>
        <Shadow>
            <Attribution user={user} canViewForHire={canViewForHire} />
            {user?.about_bio && <Bio isHovered={isHovered}>{user.about_bio}</Bio>}
        </Shadow>
    </Container>
)

export default CutoutAttribution
